import React, { useState } from 'react';

const AnnouncementBar = ({ message, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{
      backgroundColor: '#ff0000',
      color: '#ffffff',
      textAlign: 'center',
      padding: '10px',
      fontWeight: 'bold',
      width: '100%',
      zIndex: 1000,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <span>{message}</span>
      <button
        onClick={handleClose}
        style={{
          position: 'absolute',
          right: '10px',
          background: 'none',
          border: 'none',
          color: 'white',
          fontSize: '20px',
          cursor: 'pointer',
        }}
      >
        &times;
      </button>
    </div>
  );
};

export default AnnouncementBar;

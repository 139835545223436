import './Home.css';
import HomeCarousel from '../Utils/Carousel';
import carsole4 from '../Assets/Images/Carsouel-4.jpg'

function Home({setCurrentComponent}) {
 //<a href="upi://pay?pa=UPIID@oksbi&amp;pn=JOHN BRITAS AK &amp;cu=INR" class="upi-pay1">Pay Now !</a>
    //payment url may be used for feture
    // openPaymentApp = async (payApp) => {

    //     let url = '';
    //     switch(payApp) {
    //         case 'PAYTM'   : url = 'paytmmp://'; break;
    //         case 'GPAY'    : url = 'tez://upi/'; break;
    //         case 'PHONEPE' : url = 'phonepe://'; break;
    //     }
    //     url = url + 'pay?pa=7024293076@upi&pn=DK Bose&mc=0000&tr=123456789ABCDEFG&tn=HelloWorld&am=11&cu=INR'
    //     console.log('URL : ',url);
    //     try {
    //         await Linking.openURL(url);
    //     }
    //     catch (err) {
    //         console.error('ERROR : ',err);
    //     }
    // }

    // render(){
    //     return (
    //         <View style={{alignItems:"center",justifyContent:"center",flex:1}}>
    //             <Button title='PAYTM'    onPress={() => this.openPaymentApp('PAYTM')}/>
    //             <Button title='GPAY'     onPress={() => this.openPaymentApp('GPAY')}/>
    //             <Button title='PHONE PE' onPress={() => this.openPaymentApp('PHONEPE')}/>
    //         </View>
    //     );
    // }
    const handleDonateNowClick = () => {
        setCurrentComponent('DonateNow');  
    };
 
  return (
    <div className="home-container">
      <div className="left-half">
        <HomeCarousel />
      </div>
      <div className="right-half">
        <button className="top-button" onClick={handleDonateNowClick}>DonateNow</button>
        <img 
          src={carsole4}
          alt="Right side" 
          className="right-image"
        />
      </div>
    </div>
  );
}

export default Home;

import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { DonationTableConfig } from "../Configs/Config";

const DonarDetails = ({donarDetails,isLoading,getWachDonationDetails}) => {
    const [filteredData, setFilteredData] = useState(donarDetails?.donationDetailsList);
    const [searchedColumn, setSearchedColumn] = useState('');
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchedColumn(dataIndex);

        const filtered = donarDetails?.donationDetailsList.filter(item =>
            item[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase())
        );
        setFilteredData(filtered);
    };
    useEffect(() => {
        setFilteredData(donarDetails?.donationDetailsList)
    }, [donarDetails?.donationDetailsList])

    const handleReset = (clearFilters, dataIndex,setSelectedKeys,selectedKeys, confirm,) => {
        clearFilters();
        setSearchedColumn('');
        setSelectedKeys([]);
        setFilteredData(donarDetails?.donationDetailsList);
        handleSearch(selectedKeys, confirm, dataIndex)
        getWachDonationDetails();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex,setSelectedKeys,selectedKeys, confirm,)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => document.querySelector('.ant-table-filter-dropdown input')?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span style={{ fontWeight: 'bold' }}>{text}</span>
            ) : (
                text
            ),
    });

    const columns = DonationTableConfig.Columns.map(column => ({
        ...column,
        ...getColumnSearchProps(column.dataIndex),
    }));

    return (
        <Table
            columns={columns}
            dataSource={filteredData}
            rowKey={(record, index) => index}
            loading={isLoading}
            style={{ width: '100%'}}
            pagination={{
                total: donarDetails?.donationDetailsList?.length,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `Total ${total} items`,
            }}
        />   
    );
}

export default DonarDetails;

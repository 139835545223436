import React, { useState } from 'react'
import { Layout } from 'antd'
import Home from './Home'
import HeaderPart from './Header'
import FooterPart from './Footer'
import DonationForm from './DonationForm'
import DonarDetails from './DonationDetail'
import useWachDonationDetails from './Hooks/getDonationDetails'
import AnnouncementBar from '../Utils/AnnouncementBar'

const { Header, Footer, Content } = Layout

const Main = () => {
    const [currentComponent, setCurrentComponent] = useState('Home')
    //to get donar details
    const { mutate: mutateGetWachDonationDetails, isLoading, data } = useWachDonationDetails();

    // Function to change component and update announcement

    const renderComponent = () => {
        switch(currentComponent) {
            case 'Home':
                return <Home  setCurrentComponent={setCurrentComponent}/>;
            case 'DonateNow':
                return <DonationForm />;
            case 'DonarDetails':
                return <DonarDetails 
                    donarDetails={data} 
                    isLoading={isLoading} 
                    getWachDonationDetails={mutateGetWachDonationDetails}
                />;
            case 'Contact':
                return <Home />;
            default:
                return <Home />;
        }
    }
  
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AnnouncementBar message="This site is under construction" />
            <Header style={{ background: '#262542', textAlign: 'center', color: "#ffffff" }}>
                <HeaderPart 
                    setCurrentComponent={setCurrentComponent}
                    getWachDonationDetails={mutateGetWachDonationDetails}
                />
            </Header>
            <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {renderComponent()}
            </Content>
            <Footer style={{ background: '#132644', textAlign: 'center', color: "#ffffff",padding: '0px' }}>
                <FooterPart/>
            </Footer>
        </Layout>
    )
}

export default Main

import React from 'react';
import LogoGreen from '../Assets/Images/LogoGreen.png';
import instagram from '../Assets/Images/instagram.svg'
import twitter from '../Assets/Images/twitter.svg'

const FooterPart = () => {
    return (
        <>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={LogoGreen} alt="Company Logo" style={{ height: '50px' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                    <a href="mailto:team.weallcanhelp@gmail.com" style={{ color: '#fff' }}>
                        team.weallcanhelp@gmail.com
                    </a>
                </div>
                <div style={{ display: 'flex', marginRight: '10px', marginTop: '10px',gap:'10px' }}>
                    <a href="https://bit.ly/3Oh8D6R" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="Twitter" />
                    </a>
                    <a href="https://bit.ly/3vt0Rhp" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram" />
                    </a>
                </div>

            </div>
            <div style={{ textAlign: 'center', width: '100%', backgroundColor: '#000000' }}>
                    Copyright © 2022 | All rights reserved.
            </div>
        </>
    );
};

export default FooterPart;

import { UserOutlined, MailOutlined,MobileOutlined, CommentOutlined } from '@ant-design/icons';
import carsole1 from '../Assets/Images/Carosal-1.jpg'
import carsole3 from '../Assets/Images/Carosal-3.jpg'
import carsole4 from '../Assets/Images/Carsouel-4.jpg'
import carsole5 from '../Assets/Images/Carsouel-5.jpg'
const RupeeSymbol = () => <span style={{ fontSize: '16px',fontWeight:500 }}>₹</span>;
   
export const NavArray = ["Home", "DonateNow", "DonarDetails", "Contact"]

    export const DonationFormConfig = {
        Fields : [
            {
                  Label : 'First Name',
                  Type : 'Text',
                  PlaceHolder : 'First Name',
                  Field: 'firstName',
                  Required : true,
                  Icon: UserOutlined                 
            },
            {
                Label : 'Last Name',
                Type : 'Text',
                PlaceHolder : 'last Name',
                Field: 'lastName',
                Required : true,
                Icon: UserOutlined
          },
          {
            Label : 'Amount',
            Type : 'Number',
            PlaceHolder : 'Amount',
            Field: 'amount',
            Required : true,
            Icon: RupeeSymbol    
          },
          {
            Label : 'Mobile Number',
            Type : 'Number',
            PlaceHolder : 'Mobile Number',
            Field: 'mobileNumber',
            Required : true,
            Icon: MobileOutlined 
          },
          {
            Label : 'Email ID',
            Type : 'Email',
            PlaceHolder : 'Email ID',
            Field: 'emailId',
            Required : true,
            Icon: MailOutlined
          },
          {
            Label : 'Comments',
            Type : 'TextArea',
            PlaceHolder : 'Comments',
            Field: 'comments',
            Required : false,
            Icon: CommentOutlined
          },
        ]
    }

    export const DonationTableConfig={
        Columns:[
          {
            title :'Id',
            dataIndex: 'userId',
            key: 'id',
          },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
              title:'Donation Date',
              dataIndex: 'donationDate',
              key: 'donationDate',
            }      
        ]}
  export const HomeSliderImages = [carsole3,carsole1,carsole4,carsole5 ];
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, InputNumber, message, Row, Spin } from 'antd'
import { DonationFormConfig } from '../Configs/Config'
import './styles.css'
import MutateaddNewDonationDetails from './Hooks/addNewDonation'



const DonationForm = () => {
    const [form] = Form.useForm()
    const [amount, setAmount] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const handleAmountChange = (value) => {
        setAmount(value)
    }
    const { mutate: mutateAddDonation,
        isLoading: isAddDonationLoading,
        data: addDonationData,
        isError: isAddDonationError,
        isSuccess: isAddDonationSuccess,

    } = MutateaddNewDonationDetails();

    const handleSubmit = (values) => {
        setHasSubmitted(true);
        const fullName = `${values.firstName} ${values.lastName}`
        const formData = { ...values, name: fullName }
        // Ensure all undefined values are set to empty strings and convert all values to strings
        Object.keys(formData).forEach(key => {
            if (formData[key] === undefined) {
                formData[key] = ''
            } else {
                formData[key] = String(formData[key])
            }
        })
        mutateAddDonation(formData);
    }

    useEffect(() => {
        if (!hasSubmitted) return
        if (isAddDonationSuccess && addDonationData?.statusCode === "1" && !isAddDonationError) {
            handleReset();
            message.success(addDonationData?.statusMessage, 5); 
        }
        if (isAddDonationError || addDonationData?.statusCode !== "1") {
            handleReset();
            message.error('Failed to Donate', 5);
        }
        // eslint-disable-next-line
    }, [isAddDonationSuccess,isAddDonationError]);

    const handleReset = () => {
        form.resetFields()
        setAmount('') // Reset the amount state
    }

    const renderFormFields = () => {
        return DonationFormConfig.Fields.map((field) => {
            let inputComponent
            switch (field.Type) {
                case 'Text':
                    inputComponent = <Input placeholder={field.PlaceHolder} prefix={<field.Icon />} />
                    break
                case 'Email':
                    inputComponent = <Input type="email" placeholder={field.PlaceHolder} prefix={<field.Icon />} />
                    break
                case 'TextArea':
                    inputComponent = <Input.TextArea placeholder={field.PlaceHolder} prefix={<field.Icon />} />
                    break
                    case 'Number':
                        inputComponent = (
                            <InputNumber
                                className="custom-number-input"
                                placeholder={field.PlaceHolder}
                                style={{ width: '100%' }}
                                onChange={field.Field === 'amount' ? handleAmountChange : null}
                                prefix={<field.Icon />}
                                min={1}
                                step={1}
                                onKeyDown={(e) => {
                                    const acceptedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                                    if (!/[0-9]/.test(e.key) && !acceptedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        );
                        break;
                default:
                    inputComponent = <Input placeholder={field.PlaceHolder} />
            }

            if (field.Field === 'firstName' || field.Field === 'lastName') {
                return (
                    <Col span={12} key={field.Field}>
                        <Form.Item
                            name={field.Field}
                            rules={[{ required: field.Required, message: `${field.Label} is required` }]}
                        >
                            {inputComponent}
                        </Form.Item>
                    </Col>
                )
            }
            return (
                <Form.Item
                    key={field.Field}
                    name={field.Field}
                    rules={[
                        { required: field.Required, message: `${field.Label} is required` },
                        {
                            validator: (_, value) => {
                                if (field.Field === 'mobileNumber') {
                                    if (value && !/^\d{10}$/.test(value)) {
                                        return Promise.reject(`${field.Label} must be exactly 10 digits`);
                                    }
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    {inputComponent}
                </Form.Item>

            )
        })
    }

    return (
        <Spin spinning={isAddDonationLoading}  tip="Loading...">
        <div className="form-container animated-container" style={{ position: 'relative' }}>
            <div className="background-image"></div>
            <h2>Donation Form</h2>
            <Form
                form={form}
                onFinish={handleSubmit}
            >
                <Row gutter={16}>
                    {renderFormFields().filter(field => field.key === 'firstName' || field.key === 'lastName')}
                </Row>
                {renderFormFields().filter(field => field.key !== 'firstName' && field.key !== 'lastName')}
                <Form.Item>
                    <Button type="default" onClick={handleReset} style={{ marginRight: '10px' }}>Reset</Button>
                    <Button type="primary" htmlType="submit">
                        {amount ? `Donate ${amount} Now` : 'Donate Now'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
        </Spin>
    )
}

export default DonationForm

import { Flex } from "antd";
import { NavArray } from "../Configs/Config";

const HeaderPart = ({setCurrentComponent,getWachDonationDetails}) => {
  
  // to get the Admin details
  //const [data, setData] = useState([])
  // const getWachTeamDetails = () => {
  //   fetch('https://z6qeyg2lo2.execute-api.ap-south-1.amazonaws.com/dev/getWachTeamDetails')
  //     .then(response => response.json())
  //     .then(data => setData(data))
  //     .catch(error => console.error('Error fetching data:', error))
  // }

  

  const Handleclick = (item) => {
    if(item === 'DonarDetails'){
       getWachDonationDetails();
    }
    setCurrentComponent(item);
  }

  return (
    <Flex>
      <div className="header-container">
        {NavArray.map((item) => (
          <div
            onClick={() => Handleclick(item)}
            className="nav-item"
            key={item}
          >
            {item}
          </div>
        ))}
      </div>
    </Flex>
  );
};

export default HeaderPart;

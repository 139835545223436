import React from 'react';
import { Carousel } from 'antd';
import './Carousel.css';
import { HomeSliderImages } from '../Configs/Config';


function HomeCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows:true,
  };

  return (
    <Carousel {...settings}>
      {HomeSliderImages.map((image, index) => (
        <div key={index}>
          <img src={image} alt={`Slide ${index + 1}`} className="carousel-image" />
        </div>
      ))}
    </Carousel>
  );
}

export default HomeCarousel;
